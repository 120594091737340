import axios from "axios";
import config from "./config";
import { getToken, removeToken } from "@/utils/token";
import { Message } from "element-ui";
import MsgMain from '@/components/CustomPopup/msg.js'
import router from "@/router";
import store from "@/store";
// 使用Vuex做全局loading时使用
// import store from '@/store'
export default function $axios(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: {
        "X-Access-Token": getToken(),
      },
      transformResponse: [function (data) {}],
    });
    // request 拦截器
    // 请求相关处理 请求拦截 在请求拦截中可以补充请求相关的配置
    instance.interceptors.request.use(
      (config) => {
        let token = getToken();
        const ResourceCode = store.state.resource.code;
        if (token) {
          // config.headers["X-Access-Token"] = token;
          config.headers[ 'Hummer-Auth' ] = token
          // config.headers.accessToken = token;
          config.headers["Resource-Code"] = ResourceCode;
          setTimeout(() => {
            store.dispatch("SetCode", "");
          }, 0);
          // 让每个请求携带自定义 token 请根据实际情况自行修改
        }
        // 将配置完成的config对象返回出去，如果不返回 请求不会进行
        return config;
      },
      (error) => {
        // 请求错误时
        console.log("request:", error);
        // 1. 判断请求超时
        if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1) {
          console.log("timeout请求超时");
          // return service.request(originalRequest);// 再重复请求一次
        }
        // 2. 需要重定向到错误页面
        const errorInfo = error.response;
        console.log(errorInfo);
        if (errorInfo) {
          error = errorInfo.data; // 页面那边 catch 的时候就能拿到详细的错误信息，看最下边的 Promise.reject
          const errorStatus = errorInfo.status; // 404 403 500 ...
          router.push({
            path: `/error/${errorStatus}`,
          });
        }
        // 请求发生错误时的相关处理 抛出错误
        // 在调用的那边可以拿到 (catch) 你想返回的错误信息
        return Promise.reject(error);
      }
    );
    // response 拦截器
    instance.interceptors.response.use(
      (response) => {
        let data;
        // IE9 时 response.data 是 undefined
        // 因此需要使用 response.request.responseText（Stringify 后的字符串）
        if (response.data == undefined && (!response.config.url.includes('cashback/queryPayStatus')
        &&!response.config.url.includes('detection/CancelPayment'))
        ) {
          if(response.request.responseType == 'blob'){
            return response.request.response;
          }
          data = JSON.parse(response.request.response);
          if(data.code == 112 || data.message.includes('失效')){
            msgInvalidLogin(data.message);
          }
          if(data.code == 500){
            if(data.message){
              // let alertList = ['订单', '支付'];
              // let alertFlag = alertList.some(item => data.message.includes(item));
              MsgMain.error(data.message);
            }
          }
        } else if (response.config.url.includes('cashback/queryPayStatus') || response.config.url.includes('detection/CancelPayment')){
          data = JSON.parse(response.request.response);
        }else {
          data = JSON.parse(response.request.response);

        }
        // 根据返回的 code 值来做不同的处理
        switch (data.rc) {
          case 1:
            console.log(data.desc);
            break;
          case 0:
          // store.commit("changeState");
          // console.log('登录成功')
          default:
        }
        return data;
      },
      (err) => {
        if (err && err.response) {
          let res = null;
          switch (err.response.status) {
            case 400:
              err.message = "请求错误";
              break;
            case 401:
              err.message = "未授权，请登录";
              break;
            case 403:
              err.message = "拒绝访问";
              break;
            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`;
              break;
            case 408:
              err.message = "请求超时";
              break;
            case 500:
              // err.message = '服务器内部错误'
              res = JSON.parse(err.response.request.response);
              if (res.error == "Internal Server Error") {
                // msgInvalidLogin(res.message);
              }
              err.message = "服务器内部错误";
              break;
            case 501:
              err.message = "服务未实现";
              break;
            case 502:
              err.message = "网关错误";
              break;
            case 503:
              err.message = "服务不可用";
              break;
            case 504:
              err.message = "网关超时";
              break;
            case 505:
              err.message = "HTTP 版本不受支持";
              break;
            default:
          }
        }
        console.error(err);
        return Promise.reject(err);
        // 返回接口返回的错误信息
      }
    );

    // 请求处理
    instance(options)
      .then((res) => {
        resolve(res);
        return false;
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 处理登录失效的消息提示
function msgInvalidLogin(msg) {
  // Message.error(msg);
  MsgMain.error(msg);
  // let second = 3;
  const timer = setInterval(() => {
    // second--;
    // if (!second) {
      clearInterval(timer);
      removeToken();
      store.dispatch('setUserInfo', null);
      router.push({
        path: "/user/login",
      });
      // location.reload();
      // store.dispatch('LogOut').then(() =>{
      //   location.reload()
      // })
    // }
  }, 1000);
}
